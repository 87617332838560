import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useHeroStyles = makeStyles(() =>
  createStyles({
    heroWrap: {
      position: 'relative',
      height: '550px',
      '@media (max-width: 500px)': {
        height: '440px',
      },
    },
    root: {
      height: '300px',
      position: 'relative',
      zIndex: 20,
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 500px)': {
        '& > div': {
          maxWidth: '100%',
          width: '100%',
          alignItems: 'center',
          flexFlow: 'column',
          display: 'flex',
        },
      },
    },
    container: {
      position: 'relative',
    },
    title: {
      fontWeight: 'bold',
    },
    noPaymentDetails: {
      textAlign: 'left',
      '@media (max-width: 500px)': {
        textAlign: 'center',
      },
    },
    techIconsWrap: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '300px',
      zIndex: 10,
      '@media (max-width: 860px)': {
        width: '250px',
        top: '100px',
      },
      '@media (max-width: 670px)': {
        width: '190px',
        top: '200px',
      },
      '@media (max-width: 500px)': {
        display: 'none',
      },
    },
  }),
);
