import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useDiscoverStyles = makeStyles((theme) =>
  createStyles({
    containerWrap: {
      backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 98.82%, 1)'/><path d='M3.25 10h13.5M10 3.25v13.5'  stroke-linejoin='round' stroke-linecap='round' stroke-width='0.5' stroke='hsla(259, 0%, 91%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")`,
    },
    root: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      display: 'flex',
      flexFlow: 'row wrap',
      gap: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    tabs: {
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        width: '12px',
        display: 'flex',
        alignItems: 'center',
        '&:after': {
          content: '""',
          backgroundColor: theme.palette.secondary.main,
          display: 'block',
          borderRadius: '100%',
          width: '12px',
          height: '12px',
        },
      },
    },
    tab: {
      textAlign: 'left',
      fontWeight: 600,
      textTransform: 'none',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
    },
    nav: {
      flex: `0 0 250px`,
      '@media (max-width: 700px)': {
        flex: '1 0 100%',
      },
    },
    contentWrap: {
      flex: `1`,
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      fontSize: '2.78rem',
    },
  }),
);
