/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Box, Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { DoubleArrowRounded } from '@material-ui/icons';
import { useDiscoverStyles } from './styles';
import { SECONDARY_MAIN_COLOR } from '../../../theme/constants';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const tabs = [
  { title: 'discover.tab.myUmo', content: 'discover.tab.myUmo.content', id: 'discover-my-umo' },
  { title: 'discover.tab.users', content: 'discover.tab.users.content', id: 'discover-users-and-permissions' },
  {
    title: 'discover.tab.companyStructure',
    content: 'discover.tab.companyStructure.content',
    id: 'discover-company-structure',
  },
  {
    title: 'discover.tab.integrations',
    content: 'discover.tab.integrations.content',
    id: 'discover-3rd-party-integrations',
  },
  { title: 'discover.tab.billing', content: 'discover.tab.billing.content', id: 'discover-billing' },
  { title: 'discover.tab.availability', content: 'discover.tab.availability.content', id: 'discover-availability' },
];

export const Discover = ({ location }) => {
  const classes = useDiscoverStyles();
  const { t } = useTranslation('product');
  const initialIndex =
    typeof location?.hash === 'string' && location?.hash
      ? tabs.findIndex((tab) => tab.id === location.hash.slice(1))
      : 0;

  const [value, setValue] = React.useState(initialIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.containerWrap}>
      <Container>
        <div className={classes.root}>
          <Box flex="1 0 100%">
            <Typography className={classes.title} variant="h2">
              {t('discover.title')}
            </Typography>
            <Typography>{t('discover.introduction')}</Typography>
          </Box>
          <div className={classes.nav}>
            <Tabs
              className={classes.tabs}
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              {tabs.map((tab, index) => {
                return <Tab className={classes.tab} key={tab.title} label={t(tab.title)} {...a11yProps(index)} />;
              })}
            </Tabs>
          </div>
          <div className={classes.contentWrap}>
            <TabPanel title={t('discover.tab.myUmo')} value={value} index={0} name="discover-my-umo">
              <Typography paragraph variant="body2">
                {t('discover.tab.myUmo.content')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.myUmo.content2')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.myUmo.content3')}
              </Typography>
            </TabPanel>
            <TabPanel title={t('discover.tab.users')} value={value} index={1} name="discover-users-and-permissions">
              <Typography paragraph variant="body2">
                {t('discover.tab.users.content')}
              </Typography>
              <Typography variant="body2" component="ol">
                <li>{t('discover.tab.users.content2')}</li>
                <li>{t('discover.tab.users.content3')}</li>
                <li>{t('discover.tab.users.content4')}</li>
                <li>{t('discover.tab.users.content5')}</li>
              </Typography>
            </TabPanel>
            <TabPanel
              title={t('discover.tab.companyStructure')}
              value={value}
              index={2}
              name="discover-company-structure"
            >
              <Typography paragraph variant="body2">
                {t('discover.tab.companyStructure.content')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.companyStructure.content2')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.companyStructure.content3')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.companyStructure.content4')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.companyStructure.content5')}
              </Typography>
            </TabPanel>
            <TabPanel
              title={t('discover.tab.integrations')}
              value={value}
              index={3}
              name="discover-3rd-party-integrations"
            >
              <Typography paragraph variant="body2">
                {t('discover.tab.integrations.content')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.integrations.content2')}
              </Typography>
              <Typography variant="body2" component="ul" style={{ paddingLeft: '40px' }}>
                <li>{t('discover.tab.integrations.content3')}</li>
                <li>{t('discover.tab.integrations.content4')}</li>
              </Typography>
            </TabPanel>
            <TabPanel title={t('discover.tab.billing')} value={value} index={4} name="discover-billing">
              <Typography paragraph variant="body2">
                {t('discover.tab.billing.content2')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.billing.content3')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.billing.content31')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.billing.content4')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.billing.content41')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.billing.content6')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.billing.content61')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.billing.content7')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.billing.content71')}
              </Typography>
            </TabPanel>
            <TabPanel title={t('discover.tab.availability')} value={value} index={5} name="discover-availability">
              <Typography paragraph variant="body2">
                {t('discover.tab.availability.content')}
              </Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.availability.content2')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.availability.content3')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.availability.content31')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.availability.content4')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.availability.content41')}
              </Typography>
              <Typography variant="subtitle1">{t('discover.tab.availability.content7')}</Typography>
              <Typography paragraph variant="body2">
                {t('discover.tab.availability.content71')}
              </Typography>
            </TabPanel>
          </div>
        </div>
      </Container>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, name, index, title, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={name}
      aria-labelledby={`vertical-tab-${name}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Paper>
          <Box display="flex" flexWrap="wrap" p={2}>
            <Box flex="1 0 100%" display="flex" alignItems="center">
              <Box display="flex" mr={1} color={SECONDARY_MAIN_COLOR}>
                <DoubleArrowRounded color="inherit" />
              </Box>
              <Box flex="1">
                <Typography variant="h3">{title}</Typography>
              </Box>
            </Box>

            <Box mt={3} flex="1 0 100%">
              {children}
            </Box>
          </Box>
        </Paper>
      )}
    </div>
  );
}
