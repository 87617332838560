import * as React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useWhatCanYouExpectStyles } from './styles';
import { Card } from './Card';

import Curve from '../../../images/curve.svg';

export const WhatCanYouExpect = () => {
  const classes = useWhatCanYouExpectStyles();
  const { t } = useTranslation('product');
  return (
    <Box pt={10} pb={10} className={classes.root} id="what-can-you-expect">
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box className={classes.titleWrap}>
              <Typography className={classes.title} variant="h2">
                {t('whatCanYouExpect.title')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box display="flex" justifyContent="flex-end">
              <Box
                maxWidth="570px"
                flex="1"
                maxHeight="850px"
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                alignItems="flex-end"
                className={classes.cardWrap}
              >
                <Box width="250px" mt={12.5} mx={2} mb={2}>
                  <Card
                    heading={t('whatCanYouExpect.management.heading')}
                    content={() => {
                      return (
                        <>
                          <Typography paragraph variant="body2">
                            {t('whatCanYouExpect.management.content')}
                          </Typography>
                          <Typography paragraph variant="body2">
                            {t('whatCanYouExpect.management.content1')}
                          </Typography>
                          <Typography style={{ paddingLeft: '20px' }} variant="body2" component="ul">
                            <li>{t('whatCanYouExpect.management.content2')}</li>
                            <li>{t('whatCanYouExpect.management.content3')}</li>
                          </Typography>
                        </>
                      );
                    }}
                  />
                </Box>
                <Box width="250px" mx={2} mb={2}>
                  <Card
                    heading={t('whatCanYouExpect.design.heading')}
                    content={() => {
                      return (
                        <>
                          <Typography paragraph variant="body2">
                            {t('whatCanYouExpect.design.content')}
                          </Typography>
                          <Typography style={{ paddingLeft: '20px' }} variant="body2" component="ul">
                            <li>{t('whatCanYouExpect.design.content1')}</li>
                            <li>{t('whatCanYouExpect.design.content2')}</li>
                          </Typography>
                        </>
                      );
                    }}
                  />
                </Box>
                <Box width="250px" mx={2} mb={2} mt={2}>
                  <Card
                    heading={t('whatCanYouExpect.archiving.heading')}
                    content={() => {
                      return (
                        <>
                          <Typography paragraph variant="body2">
                            {t('whatCanYouExpect.archiving.content')}
                          </Typography>
                          <Typography paragraph variant="body2">
                            {t('whatCanYouExpect.archiving.content1')}
                          </Typography>
                          <Typography style={{ paddingLeft: '20px' }} variant="body2" component="ul">
                            <li>{t('whatCanYouExpect.archiving.content2')}</li>
                            <li>{t('whatCanYouExpect.archiving.content3')}</li>
                          </Typography>
                        </>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.curveContainer}>
          <img src={Curve} alt="shows arrow" />
        </Box>
      </Container>
    </Box>
  );
};
