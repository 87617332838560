import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useWhatCanYouExpectStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundImage: `url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 98.82%, 1)'/><path d='M3.25 10h13.5M10 3.25v13.5'  stroke-linejoin='round' stroke-linecap='round' stroke-width='0.5' stroke='hsla(259, 0%, 91%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")`,
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '3.33rem',
    },
    titleWrap: {
      maxWidth: '440px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    cardWrap: {
      '@media (max-width: 640px)': {
        flexFlow: 'row wrap',
        justifyContent: 'center',
        '& > div': {
          marginTop: 0,
          width: '300px',
        },
      },
      '@media (max-width: 380px)': {
        '& > div': {
          width: '100%',
        },
      },
    },
    container: {
      position: 'relative',
    },
    curveContainer: {
      position: 'absolute',
      left: '100px',
      bottom: '140px',
      width: '340px',
      transform: 'rotate(17deg)',
      '@media (max-width: 1000px)': {
        display: 'none',
      },
    },
  }),
);

export const useCardStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    wrap: {
      padding: theme.spacing(2),
    },
  }),
);
