import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SECONDARY_BACKGROUND } from '../../../theme/constants';

const GAP = 6;
export const useProductFeatureStyles = makeStyles((theme) =>
  createStyles({
    containerWrap: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      backgroundColor: ({ align }) => (align === 'left' ? SECONDARY_BACKGROUND : 'transparent'),
    },
    root: {
      display: 'flex',
      flexFlow: 'row',
      gap: theme.spacing(GAP),
    },
    contentWrap: {
      flex: `1 0 calc(50% - ${theme.spacing(GAP)}px)`,
      order: ({ align }) => (align === 'right' ? 2 : 1),
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      fontSize: '2.78rem',
    },
    content: {},
    imageWrap: {
      flex: `1 0 calc(50% - ${theme.spacing(GAP)}px)`,
      order: ({ align }) => (align === 'right' ? 1 : 2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (max-width: 991px)': {
        display: 'none',
      },
    },
    imageInnerWrap: {
      paddingTop: '100%',
      position: 'relative',
      flex: '1',
      '& img': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  }),
);
