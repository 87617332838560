import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SECONDARY_BACKGROUND } from '../../../theme/constants';

export const useHowItWorksStyles = makeStyles(() =>
  createStyles({
    root: {
      background: SECONDARY_BACKGROUND,
    },
    title: {
      fontWeight: 'bold',
    },
  }),
);

export const useStepStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(3),
    },
    circle: {
      border: '2px solid',
      padding: theme.spacing(1),
      borderRadius: '100%',
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circleInner: {
      fontSize: '22px',
      fontWeight: 'bold',
    },
  }),
);
