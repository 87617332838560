import * as React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { DoubleArrowRounded } from '@material-ui/icons';
import { useCardStyles } from './styles';
import { SECONDARY_MAIN_COLOR } from '../../../theme/constants';

export const Card = ({ heading, content }) => {
  const classes = useCardStyles();
  return (
    <Paper className={classes.root}>
      <Box className={classes.wrap}>
        <Box mb={1} display="flex">
          <Box display="flex" mr={1} color={SECONDARY_MAIN_COLOR}>
            <DoubleArrowRounded color="inherit" />
          </Box>
          <Typography variant="h3">{heading}</Typography>
        </Box>
        <Box>{typeof content === 'function' ? content() : content}</Box>
      </Box>
    </Paper>
  );
};
