import * as React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useHeroStyles } from './styles';
import RegistrierungsButton from '../../Button/registrierungsButton';
import WaveBottomRight from '../../imagesSettings/Wave/waveBottomRight';
import { TechIcons } from '../../imagesSettings/TechIcons/TechIcons';
import { SECONDARY_BACKGROUND } from '../../../theme/constants';

export const Hero = () => {
  const classes = useHeroStyles();
  const { t } = useTranslation('product');

  return (
    <div className={classes.heroWrap}>
      <WaveBottomRight fill={SECONDARY_BACKGROUND} />
      <Container className={classes.container}>
        <div className={classes.techIconsWrap}>
          <TechIcons />
        </div>
        <section className={classes.root}>
          <Box maxWidth="450px">
            <Typography className={classes.title} variant="h1" component="h2">
              UMO
            </Typography>
            <Typography component="h1" variant="h2">
              {t('hero.subtitle')}
            </Typography>
            <Box mt={2}>
              <RegistrierungsButton />
              <Typography className={classes.noPaymentDetails} variant="subtitle2">
                {t('hero.subtitle.payment')}
              </Typography>
            </Box>
          </Box>
        </section>
      </Container>
    </div>
  );
};
