import { makeStyles, createStyles } from '@material-ui/core/styles';

const GAP = 6;
export const useGetStartedStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(20),
      display: 'flex',
      flexFlow: 'row wrap',
      gap: theme.spacing(GAP),
    },
    getStartedWrap: {
      flex: `1 0 calc(60% - ${theme.spacing(GAP)}px)`,
      '@media (max-width: 700px)': {
        flex: '1 0 100%',
      },
    },
    additionalBox: {
      flex: `1 0 calc(20% - ${theme.spacing(GAP)}px)`,
      display: 'flex',
      flexFlow: 'column',
    },
    boxHeading: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
    },
  }),
);
