import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Typography } from '@material-ui/core';
import { ProductPageLayout } from '../components/layout';

import Seo from '../components/seo';
import { Hero } from '../components/Product/Hero';
import { HowItWorks } from '../components/Product/HowItWorks';
import { WhatCanYouExpect } from '../components/Product/WhatCanYouExpect';
import { FeatureRow, ProductFeature } from '../components/Product/ProductFeature';
import { Discover } from '../components/Product/Discover';
import { GetStarted } from '../components/Product/GetStarted';

import InstructionEditorIllustration from '../images/product-feature-editor.svg';
import ArchivingIllustration from '../images/product-feature-archiving.svg';
import AssignmentsIllustration from '../images/product-feature-assignments.svg';
import DateMonitoringIllustration from '../images/product-feature-date-monitoring.svg';

const ProductPage = ({ pageContext, location }) => {
  const { t } = useTranslation('product');
  return (
    <ProductPageLayout>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />
      <Hero />
      <HowItWorks />
      <WhatCanYouExpect />
      <ProductFeature
        align="left"
        heading={t('feature.heading.instructionEditor')}
        imageSrc={InstructionEditorIllustration}
      >
        <Typography id="instruction-editor" variant="body2" paragraph>
          {t('feature.content.instructionEditor')}
        </Typography>
        <FeatureRow heading={t('feature.content.instructionEditor2')} text={t('feature.content.instructionEditor3')} />
        <FeatureRow heading={t('feature.content.instructionEditor4')} text={t('feature.content.instructionEditor5')} />
        <FeatureRow heading={t('feature.content.instructionEditor6')} text={t('feature.content.instructionEditor7')} />
        <FeatureRow heading={t('feature.content.instructionEditor8')} text={t('feature.content.instructionEditor9')} />
      </ProductFeature>
      <ProductFeature align="right" heading={t('feature.heading.smartAssignments')} imageSrc={AssignmentsIllustration}>
        <Typography id="smart-assignments" variant="body2" paragraph>
          {t('feature.content.smartAssignments')}
        </Typography>
        <FeatureRow heading={t('feature.content.smartAssignments2')} text={t('feature.content.smartAssignments3')} />
        <FeatureRow heading={t('feature.content.smartAssignments4')} text={t('feature.content.smartAssignments5')} />
        <FeatureRow heading={t('feature.content.smartAssignments6')} text={t('feature.content.smartAssignments7')} />
      </ProductFeature>
      <ProductFeature align="left" heading={t('feature.heading.archiving')} imageSrc={ArchivingIllustration}>
        <Typography id="archiving" variant="body2" paragraph>
          {t('feature.content.archiving')}
        </Typography>
        <FeatureRow heading={t('feature.content.archiving2')} text={t('feature.content.archiving3')} />
        <FeatureRow heading={t('feature.content.archiving4')} text={t('feature.content.archiving5')} />
        <FeatureRow heading={t('feature.content.archiving6')} text={t('feature.content.archiving7')} />
        <FeatureRow heading={t('feature.content.archiving8')} text={t('feature.content.archiving9')} />
      </ProductFeature>
      <ProductFeature align="right" heading={t('feature.heading.dateMonitoring')} imageSrc={DateMonitoringIllustration}>
        <Typography id="date-monitoring" variant="body2" paragraph>
          {t('feature.content.dateMonitoring')}
        </Typography>
        <FeatureRow heading={t('feature.content.dateMonitoring4')} text={t('feature.content.dateMonitoring5')} />
        <FeatureRow heading={t('feature.content.dateMonitoring6')} text={t('feature.content.dateMonitoring7')} />
        <FeatureRow heading={t('feature.content.dateMonitoring8')} text={t('feature.content.dateMonitoring9')} />
      </ProductFeature>
      <Discover location={location} />
      <GetStarted />
    </ProductPageLayout>
  );
};
export default ProductPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
