import * as React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { HelpOutlineRounded, LocalOfferRounded, NavigateNextRounded } from '@material-ui/icons';
import { useTranslation, Link, Trans } from 'gatsby-plugin-react-i18next';
import { useGetStartedStyles } from './styles';

const REGISTER_URL = 'https://app.d-simple.com/register?referrer=d-simple.com';

export const GetStarted = () => {
  const classes = useGetStartedStyles();
  const { t } = useTranslation('product');

  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.getStartedWrap}>
          <Typography className={classes.title} variant="h2">
            {t('getStarted.title')}
          </Typography>
          <Box display="flex" mt={2}>
            <Box>
              <Button color="secondary" variant="contained" href={REGISTER_URL} target="_blank">
                {t('getStarted.cta')}
              </Button>
            </Box>
            <Box ml={2}>
              <Button endIcon={<NavigateNextRounded />} component={Link} to="/contact" variant="text">
                {t('getStarted.contact')}
              </Button>
            </Box>
          </Box>
        </div>
        <div className={classes.additionalBox}>
          <HelpOutlineRounded fontSize="large" />
          <Typography className={classes.boxHeading} variant="h4">
            {t('getStarted.furtherQuestions')}
          </Typography>

          <Typography variant="body2">
            <Trans t={t} i18nKey="getStarted.furtherQuestions.content" components={[<Link key="0" to="/faq" />]} />
          </Typography>
        </div>
        <div className={classes.additionalBox}>
          <LocalOfferRounded fontSize="large" />

          <Typography className={classes.boxHeading} variant="h4">
            {t('getStarted.pricing')}
          </Typography>
          <Typography variant="body2">
            <Trans t={t} i18nKey="getStarted.pricing.content" components={[<Link key="0" to="/prices" />]} />
          </Typography>
        </div>
      </div>
    </Container>
  );
};
