import * as React from 'react';
import { Container, Paper, Typography } from '@material-ui/core';
import { useProductFeatureStyles } from './styles';

export const ProductFeature = ({ align, imageSrc, children, heading }) => {
  const classes = useProductFeatureStyles({ align });
  return (
    <div className={classes.containerWrap}>
      <Container>
        <div className={classes.root}>
          <div className={classes.contentWrap}>
            <Typography className={classes.title} variant="h2">
              {heading}
            </Typography>

            <div className={classes.content}>{children}</div>
          </div>
          <div className={classes.imageWrap}>
            <Paper className={classes.imageInnerWrap}>
              <img src={imageSrc} alt={`illustration of ${heading}`} />
            </Paper>
          </div>
        </div>
      </Container>
    </div>
  );
};
