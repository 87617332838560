import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStepStyles } from './styles';

export const Step = ({ heading, number, content }) => {
  const classes = useStepStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.circle}>
        <span className={classes.circleInner}>{number}</span>
      </Box>
      <Box mt={2} textAlign="center">
        <Typography variant="h4">{heading}</Typography>
      </Box>
      <Box mt={1} textAlign="center">
        <Typography variant="body2">{content}</Typography>
      </Box>
    </Box>
  );
};
