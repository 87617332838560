import React from 'react';

import { Box } from '@material-ui/core';
import { COLOR_WHITE } from '../../../theme/constants';

const WaveBottomRight = ({ fill }) => (
  <>
    <Box display="flex" position="absolute" bottom={-1} right={-1} width="100%" left={0} zIndex="5">
      <svg style={{ flex: '1' }} viewBox="0 0 1911.97 669.26">
        <path
          fill={fill || COLOR_WHITE}
          fillOpacity="1"
          d="M2056.41,134.39V803.65h-1912V789.88C1043.49,842,2056.41,134.39,2056.41,134.39Z"
          transform="translate(-144.44 -134.39)"
        />
      </svg>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1911.97 669.26"><path fill="#efefef" fillOpacity="1" d="M2056.41,134.39V803.65h-1912V789.88C1043.49,842,2056.41,134.39,2056.41,134.39Z" transform="translate(-144.44 -134.39)" /></svg> */}
    </Box>
  </>
);

export default WaveBottomRight;
