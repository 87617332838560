import { Typography } from '@material-ui/core';
import * as React from 'react';

export const FeatureRow = ({ heading, text }) => {
  return (
    <div>
      <Typography variant="subtitle1">{heading}</Typography>
      <Typography paragraph variant="body2">
        {text}
      </Typography>
    </div>
  );
};
