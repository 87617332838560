import * as React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Step } from './Step';
import { useHowItWorksStyles } from './styles';

export const HowItWorks = () => {
  const { t } = useTranslation('product');
  const classes = useHowItWorksStyles();

  return (
    <div className={classes.root} id="how-it-works">
      <Container>
        <Box py={10}>
          <Grid id="specialFeatures" container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={0}>
                <Typography className={classes.title} variant="h2">
                  {t('howItWorks.title')}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <Step number="1" content={t('howItWorks.step1.content')} heading={t('howItWorks.step1.heading')} />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Step number="2" content={t('howItWorks.step2.content')} heading={t('howItWorks.step2.heading')} />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Step number="3" content={t('howItWorks.step3.content')} heading={t('howItWorks.step3.heading')} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};
